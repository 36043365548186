import React, { FunctionComponent } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { Point } from "../store/Login";

interface Props {
  points: Point[];
}

export const CustomerMap: FunctionComponent<Props> = ({ points }) => {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <MapContainer
        zoomControl={false}
        center={[62.485047797743434, 17.518386840820316]}
        zoom={5}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {points.reverse().map((marker) => (
          <Marker
            key={marker.id}
            position={marker.position}
            icon={marker.icon}
          />
        ))}
      </MapContainer>
    </div>
  );
};
