import { Icon } from "leaflet";
import blueIconImage from './img/marker-icon-2x-blue.png';
import redIconImage from './img/marker-icon-2x-red.png';
import greenIconImage from './img/marker-icon-2x-green.png';
import orangeIconImage from './img/marker-icon-2x-orange.png';
import yellowIconImage from './img/marker-icon-2x-yellow.png';
import violetIconImage from './img/marker-icon-2x-violet.png';
import greyIconImage from './img/marker-icon-2x-grey.png';
import blackIconImage from './img/marker-icon-2x-black.png';
import shadowImage from './img/marker-shadow.png';


export const blueIcon = new Icon({
	iconUrl: blueIconImage,
	shadowUrl: shadowImage,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const redIcon = new Icon({
	iconUrl: redIconImage,
	shadowUrl: shadowImage,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const greenIcon = new Icon({
	iconUrl: greenIconImage,
	shadowUrl: shadowImage,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const orangeIcon = new Icon({
	iconUrl: orangeIconImage,
	shadowUrl: shadowImage,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const yellowIcon = new Icon({
	iconUrl: yellowIconImage,
	shadowUrl: shadowImage,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const violetIcon = new Icon({
	iconUrl: violetIconImage,
	shadowUrl: shadowImage,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const greyIcon = new Icon({
	iconUrl: greyIconImage,
	shadowUrl: shadowImage,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const blackIcon = new Icon({
	iconUrl: blackIconImage,
	shadowUrl: shadowImage,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});