import { GENDER } from "./Customer";
import { Dispatch } from "redux";
import { LatLngExpression, Icon } from "leaflet";

export const UPDATE_LOGIN_STREAM = "UPDATE_LOGIN_STREAM";
export const UPDATE_LOGIN_TOTAL = "UPDATE_LOGIN_TOTAL";
export const UPDATE_LOGIN_POINTS = "UPDATE_LOGIN_POINTS";

export interface LoginStream {
  time: string;
  city: string;
  age: number;
  gender: GENDER;
  todayCount: string;
}

export interface LoginTotal {
  today: number;
  uniqueToday: number;
}

interface UpdateStreamAction {
  type: typeof UPDATE_LOGIN_STREAM;
  stream: LoginStream;
}

interface UpdateTotalAction {
  type: typeof UPDATE_LOGIN_TOTAL;
  total: LoginTotal;
}

interface UpdatePointsAction {
  type: typeof UPDATE_LOGIN_POINTS;
  points: Point[];
}

type LoginActions = UpdateStreamAction | UpdateTotalAction | UpdatePointsAction;

export interface Point {
  position: LatLngExpression;
  id: string;
  icon: Icon;
}

export interface LoginState {
  stream: LoginStream[];
  total: LoginTotal | null;
  points: Point[];
}

export const initialState: LoginState = {
  stream: [],
  total: null,
  points: []
};

export function updateLoginStream(dispatch: Dispatch) {
  return (newStream: LoginStream): void => {
    dispatch({
      type: UPDATE_LOGIN_STREAM,
      stream: newStream
    });
  };
}

export function updateLoginTotal(dispatch: Dispatch) {
  return (newTotal: LoginTotal): void => {
    dispatch({
      type: UPDATE_LOGIN_TOTAL,
      total: newTotal
    });
  };
}

export function updateLoginPoints(dispatch: Dispatch) {
  return (points: Point[]): void => {
    dispatch({
      type: UPDATE_LOGIN_POINTS,
      points: points
    });
  };
}

export function loginReducer(state = initialState, action: LoginActions) {
  if (!state) {
    return state;
  }
  switch (action.type) {
    case UPDATE_LOGIN_STREAM:
      return { ...state, stream: [...state.stream.slice(Math.max(state.stream.length - 10, 0)), action.stream]};
    case UPDATE_LOGIN_TOTAL:
      return { ...state, total:  action.total};
    case UPDATE_LOGIN_POINTS:
      return { ...state, points:  action.points};
    default:
      return state;
  }
}
