const CHEER = new Audio('/audio/169233__mlteenie__crowd-yay.wav');
const LEVEL_UP = new Audio('/audio/320657__rhodesmas__level-up-03.wav');
const FANFARE = new Audio('/audio/397355__plasterbrain__tada-fanfare-a.flac');
const CELEBRATE = new Audio('/audio/446636__nicolas4677__celebrate.wav');


export class AudioPlayer {
  public static cheer() {
    CHEER.play();
  }

  public static levelUp() {
    LEVEL_UP.play();
  }

  public static fanfare() {
    FANFARE.play();
  }

  public static celebrate() {
    CELEBRATE.play();
  }
}