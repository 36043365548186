import SockJS from 'sockjs-client';
import { Stomp, CompatClient, messageCallbackType } from '@stomp/stompjs';

export class WSClient {
  private url: string;
  private socket: WebSocket | null = null;
  private stompClient: CompatClient | null = null;

  constructor(url: string) {
    this.url = url;
  }

  public connect(callback: () => void) {
    this.disconnect();
    this.socket = new SockJS(this.url);
    this.stompClient = Stomp.over(this.socket);
    this.stompClient.reconnect_delay = 5000;
    this.stompClient.connect({}, callback, (errors: any) => console.error(errors));
  }

  public disconnect() {
    if(this.stompClient) {
      this.stompClient.disconnect();
    }
    if(this.socket) {
      this.socket.close();
    }
  }

  public subscribe(path: string, callback: messageCallbackType) {
    if(!this.stompClient) {
      return;
    }
    this.stompClient.subscribe(path, callback)
  }

  public unsubscribe(path: string) {
    if(!this.stompClient) {
      return;
    }
    this.stompClient.unsubscribe(path)
  }
}