export class ReceiverConfig {
  castReceiverManager: any = null;
  messageBus: any = null;

  setup() {
    const cast = (window as any).cast;
    cast.receiver.logger.setLevelValue(0);
    this.castReceiverManager = cast.receiver.CastReceiverManager.getInstance();
    console.log('Starting Receiver Manager');
  
    this.castReceiverManager.onReady = (event: any) => {
      console.log('Received Ready event: ' + JSON.stringify(event.data));
      this.castReceiverManager.setApplicationState('chromecast-dashboard is ready...');
    };
  
    this.castReceiverManager.onSenderConnected = (event: any) => {
      console.log('Received Sender Connected event: ' + event.senderId);
    };
  
    this.castReceiverManager.onSenderDisconnected = (event: any) => {
      console.log('Received Sender Disconnected event: ' + event.senderId);
    };
  
    this.messageBus =
      this.castReceiverManager.getCastMessageBus(
          'urn:x-cast:se.lysa.dashboard', cast.receiver.CastMessageBus.MessageType.JSON);
  
    this.messageBus.onMessage = (event: any) => {
      console.log('Message [' + event.senderId + ']: ' + event.data);
    }
  
    this.castReceiverManager.start({statusText: 'Application is starting'});
    console.log('Receiver Manager started');

  }
}