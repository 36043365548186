import React, { Component } from "react";
import { ReceiverConfig } from "./ReceiverConfig";
import { WSClient } from "../api/WSClient";
import {
  LoginStream,
  updateLoginStream,
  Point,
  updateLoginPoints,
} from "../store/Login";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { DepositStream, updateDepositStream } from "../store/Deposit";
import {
  updateCustomerStream,
  CustomerStream,
  GENDER,
  updateCustomerPoints,
} from "../store/Customer";
import { Logins } from "./Logins/Logins";
import { NewUsers } from "./Users/NewUsers";
import { Deposits } from "./Deposits/Deposits";
import { RootState } from "../store/Reducer";
import { updateModalIsVisible } from "../store/Modal";
import "./ReceiverPage.scss";
import { AudioPlayer } from "../api/AudioPlayer";
import { blueIcon, redIcon, greenIcon } from "../components/markers/Markers";
import { DateTime } from "luxon";

const WS_PATH = "https://metrics-tv.xn--mrka-5qa.se/stream";
const PAGE_TIME = 30000; //ms
const LOGIN_MARKER_REMOVAL_TIME = 5 * 60 * 1000; // 2 minutes
const PAGES = {
  DEPOSIT: <Deposits />,
  LOGINS: <Logins />,
  NEW_USERS: <NewUsers />,
};

interface PositionResponse {
  place_id: number;
  licence: string;
  osm_type: string;
  osm_id: number;
  boundingbox: string[];
  lat: string;
  lon: string;
  display_name: string;
  class: string;
  type: string;
  importance: number;
  icon: string;
}

interface State {
  activePage: number;
}

interface StateProps {
  loginStream: LoginStream[];
  loginPoints: Point[];
  customerPoints: Point[];
  depositStream: DepositStream[];
  customerStream: CustomerStream[];
}

interface DispatchProps {
  updateLoginStream: (newStream: LoginStream) => void;
  updateLoginPoints: (newPoints: Point[]) => void;
  updateDepositStream: (newStream: DepositStream) => void;
  updateCustomerStream: (newStream: CustomerStream) => void;
  updateModalIsVisible: (isVisible: boolean) => void;
  updateCustomerPoints: (newPoints: Point[]) => void;
}

type Props = DispatchProps & StateProps;

class Receiver extends Component<Props, State> {
  private receiverConfig: ReceiverConfig;
  private wsClient: WSClient;
  private pages: JSX.Element[] = [];
  private timer: NodeJS.Timeout | undefined;

  constructor(props: Props) {
    super(props);
    const script = document.createElement("script");
    script.src =
      "https://www.gstatic.com/cast/sdk/libs/receiver/2.0.0/cast_receiver.js";
    script.async = true;
    document.body.appendChild(script);
    this.receiverConfig = new ReceiverConfig();
    this.state = {
      activePage: 0,
    };
    this.wsClient = new WSClient(WS_PATH);
    this.wsClient.connect(() => {
      this.setupSubscribers();
    });
  }

  private setupSubscribers() {
    this.wsClient.subscribe("/logins", (message) => {
      const login: LoginStream = JSON.parse(message.body);
      this.props.updateLoginStream(login);
      if (!login.city || login.city === "-") {
        return;
      }
      fetch(
        `https://nominatim.openstreetmap.org/search?city=${login.city}&country=sweden&format=json`
      )
        .then((response) => response.json())
        .then((response: PositionResponse[]) => {
          if (response.length === 0) {
            return;
          }
          const point: Point = {
            id: Math.random().toString(36).substr(2, 9),
            icon: getMarker(login.gender),
            position: [
              parseFloat(response[0].lat),
              parseFloat(response[0].lon),
            ],
          };
          this.props.updateLoginPoints([...this.props.loginPoints, point]);
          setTimeout(() => {
            const points = [...this.props.loginPoints];
            const idx = points.findIndex((item) => item.id === point.id);
            if (idx === -1) {
              return;
            }
            points.splice(idx, 1);
            this.props.updateLoginPoints(points);
          }, LOGIN_MARKER_REMOVAL_TIME);
        });
    });
    this.wsClient.subscribe("/deposits", (message) => {
      const deposit: DepositStream = JSON.parse(message.body);
      // This is an amazing sleep sort. When we get multiple deposits they will hopefully be ordered from smallest to biggest
      setTimeout(() => {
        this.props.updateDepositStream(deposit);
        if (deposit.amount > 100000) {
          this.forcePage(PAGES.DEPOSIT);
          AudioPlayer.levelUp();
        }
      }, deposit.amount / 1000);
    });
    this.wsClient.subscribe("/new-customers", (message) => {
      const customer: CustomerStream = JSON.parse(message.body);
      this.props.updateCustomerStream(customer);
      this.forcePage(PAGES.NEW_USERS);
      AudioPlayer.levelUp();
      if (!customer.city || customer.city === "-") {
        return;
      }
      fetch(
        `https://nominatim.openstreetmap.org/search?city=${customer.city}&country=sweden&format=json`
      )
        .then((response) => response.json())
        .then((response: PositionResponse[]) => {
          if (response.length === 0) {
            return;
          }
          const point: Point = {
            id: Math.random().toString(36).substr(2, 9),
            icon: getMarker(customer.gender),
            position: [
              parseFloat(response[0].lat),
              parseFloat(response[0].lon),
            ],
          };
          this.props.updateCustomerPoints([
            ...this.props.customerPoints,
            point,
          ]);
        });
    });
  }

  private setupPages() {
    this.pages = [];
    if (this.props.loginStream && this.props.loginStream.length > 0) {
      this.pages.push(PAGES.LOGINS);
    }
    if (this.props.depositStream && this.props.depositStream.length > 0) {
      this.pages.push(PAGES.DEPOSIT);
    }
    if (this.props.customerStream && this.props.customerStream.length > 0) {
      this.pages.push(PAGES.NEW_USERS);
    }
  }

  private forcePage(page: JSX.Element) {
    setTimeout(() => {
      this.setupPages();
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.setState({ activePage: this.pages.indexOf(page) });
      this.rotatePageTimer();
    }, 10);
  }

  private midnightClear() {
    setTimeout(() => {
      this.props.updateCustomerPoints([]);
      this.midnightClear();
    }, DateTime.fromFormat("24:00:00", "HH:mm:ss").diff(DateTime.fromJSDate(new Date())).milliseconds);
  }

  componentDidMount() {
    setTimeout(this.receiverConfig.setup, 2000);
    this.rotatePageTimer();
    this.midnightClear();
  }

  rotatePageTimer() {
    this.timer = setTimeout(() => {
      this.setupPages();
      if (this.pages.length > 0) {
        const newPage = (this.state.activePage + 1) % this.pages.length;
        this.setState({
          activePage: newPage,
        });
      }
      this.rotatePageTimer();
    }, PAGE_TIME);
  }

  render() {
    if (this.pages.length > 0) {
      return (
        <React.Fragment>{this.pages[this.state.activePage]}</React.Fragment>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#FFF",
          }}
        >
          <h1>Väntar på data</h1>
        </div>
      );
    }
  }
}

function getMarker(gender: GENDER) {
  if (gender === GENDER.MALE) {
    return blueIcon;
  } else if (gender === GENDER.FEMALE) {
    return redIcon;
  } else {
    return greenIcon;
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateLoginStream: updateLoginStream(dispatch),
    updateLoginPoints: updateLoginPoints(dispatch),
    updateDepositStream: updateDepositStream(dispatch),
    updateCustomerStream: updateCustomerStream(dispatch),
    updateModalIsVisible: updateModalIsVisible(dispatch),
    updateCustomerPoints: updateCustomerPoints(dispatch),
  };
};

const mapStateToProps = (state: RootState) => {
  return {
    loginStream: state.logins.stream,
    loginPoints: state.logins.points,
    depositStream: state.deposits.stream,
    customerPoints: state.customers.points,
    customerStream: state.customers.stream,
  };
};

export const ReceiverPage = connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Receiver);
