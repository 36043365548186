import React, { FunctionComponent } from "react";
import { GENDER } from "../store/Customer";

interface Props {
  gender: GENDER;
}

export const GenderIcon: FunctionComponent<Props> = ({ gender }) => {
  let icon = "?";
  switch (gender) {
    case GENDER.MALE:
      icon = "&#9794;";
      break;
    case GENDER.FEMALE:
      icon = "&#9792;";
      break;
    case GENDER.CORPORATION:
      icon = "&#x1F3ED;";
      break;
    default:
      break;
  }
  return <span dangerouslySetInnerHTML={{__html: icon}}></span>;
};
