import { Dispatch, AnyAction } from "redux";

const UPDATE_DEPOSIT_STREAM = "UPDATE_DEPOSIT_STREAM";
const UPDATE_DEPOSIT_TOTAL = "UPDATE_DEPOSIT_TOTAL";

export enum CHANNELS {
  SWISH = "SWISH",
  DIRECT_DEPOSIT = "DIRECT_DEPOSIT",
  AUTOGIRO = "AUTOGIRO",
  BANKGIRO = "BANKGIRO",
  ISK_TRANSFER = "ISK_TRANSFER",
  VIRTUAL_ACCOUNT = "VIRTUAL_ACCOUNT",
  MOBILEPAY = "MOBILEPAY",
  DIRECT_DEBIT = "DIRECT_DEBIT",
}

export enum Country {
  SWEDEN = "SE",
  DENMARK = "DK",
  FINLAND = "FI",
  GERMANY = "DE",
}

export interface DepositStream {
  amount: number;
  time: string;
  channel: CHANNELS;
  todayCount: number;
  todayInvestmentAmount: number;
  todaySavingsAmount: number;
  country?: Country;
}

export interface DepositTotal {
  today: number;
  amount: number;
}

interface UpdateStreamAction {
  type: typeof UPDATE_DEPOSIT_STREAM;
  stream: DepositStream;
}

interface UpdateTotalAction {
  type: typeof UPDATE_DEPOSIT_TOTAL;
  total: DepositTotal;
}

type DepositActions = UpdateStreamAction | UpdateTotalAction;

export interface DepositState {
  stream: DepositStream[];
  total: DepositTotal | null;
}

const initialState: DepositState = {
  stream: [],
  total: null,
};

export function updateDepositStream(dispatch: Dispatch<AnyAction>) {
  return (newStream: DepositStream): void => {
    dispatch({
      type: UPDATE_DEPOSIT_STREAM,
      stream: newStream,
    });
  };
}

export function updateDepositTotal(dispatch: Dispatch<AnyAction>) {
  return (newTotal: DepositTotal): void => {
    dispatch({
      type: UPDATE_DEPOSIT_TOTAL,
      total: newTotal,
    });
  };
}

export function depositReducer(state = initialState, action: DepositActions) {
  if (!state) {
    return state;
  }
  switch (action.type) {
    case UPDATE_DEPOSIT_STREAM:
      return {
        ...state,
        stream: [
          ...state.stream.slice(Math.max(state.stream.length - 10, 0)),
          action.stream,
        ],
      };
    case UPDATE_DEPOSIT_TOTAL:
      return { ...state, total: action.total };
    default:
      return state;
  }
}
