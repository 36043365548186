import { Dispatch, AnyAction } from "redux";
import { Point } from "./Login";

const UPDATE_CUSTOMER_STREAM = "UPDATE_CUSTOMER_STREAM";
const UPDATE_CUSTOMER_TOTAL = "UPDATE_CUSTOMER_TOTAL";
const UPDATE_CUSTOMER_POINTS = "UPDATE_CUSTOMER_POINTS";

export enum GENDER {
  MALE = "MALE",
  FEMALE = "FEMALE",
  CORPORATION = "CORPORATION"
}

export interface CustomerStream {
  time: string;
  age: number;
  gender: GENDER;
  todayCount: number;
  totalCount: number;
  city: string;
}

export interface CustomerTotal {
  today: string;
}

interface UpdateStreamAction {
  type: typeof UPDATE_CUSTOMER_STREAM;
  stream: CustomerStream;
}

interface UpdateTotalAction {
  type: typeof UPDATE_CUSTOMER_TOTAL;
  total: CustomerTotal;
}

interface UpdatePointsAction {
  type: typeof UPDATE_CUSTOMER_POINTS;
  points: Point[];
}

type CustomerActions =
  | UpdateStreamAction
  | UpdateTotalAction
  | UpdatePointsAction;

export interface CustomerState {
  stream: CustomerStream[];
  total: CustomerTotal | null;
  points: Point[];
}

const initialState: CustomerState = {
  stream: [],
  total: null,
  points: []
};

export function updateCustomerStream(dispatch: Dispatch<AnyAction>) {
  return (newStream: CustomerStream): void => {
    dispatch({
      type: UPDATE_CUSTOMER_STREAM,
      stream: newStream
    });
  };
}

export function updateCustomerTotal(dispatch: Dispatch<AnyAction>) {
  return (newTotal: CustomerTotal): void => {
    dispatch({
      type: UPDATE_CUSTOMER_TOTAL,
      total: newTotal
    });
  };
}

export function updateCustomerPoints(dispatch: Dispatch<AnyAction>) {
  return (newPoints: Point[]): void => {
    dispatch({
      type: UPDATE_CUSTOMER_POINTS,
      points: newPoints
    });
  };
}

export function customerReducer(state = initialState, action: CustomerActions) {
  if (!state) {
    return state;
  }
  switch (action.type) {
    case UPDATE_CUSTOMER_STREAM:
      return {
        ...state,
        stream: [
          ...state.stream.slice(Math.max(state.stream.length - 10, 0)),
          action.stream
        ]
      };
    case UPDATE_CUSTOMER_TOTAL:
      return { ...state, total: action.total };
    case UPDATE_CUSTOMER_POINTS:
      return { ...state, points: action.points };
    default:
      return state;
  }
}
