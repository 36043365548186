import React, { FunctionComponent } from "react";

interface Props {
  number: number | null;
  decimals?: number;
}

export const FormattedNumber: FunctionComponent<Props> = ({
  number,
  decimals = 0,
}) => {
  if (number === null) {
    return null;
  }
  const value = parseFloat(number.toString());
  const formattedValue = value
    .toFixed(decimals)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .replace(/\./g, ",");
  return (
    <React.Fragment>
      <span className="text-nowrap">{formattedValue}</span>
    </React.Fragment>
  );
};
