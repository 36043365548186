import React, { FunctionComponent } from "react";
import DayJs from "dayjs";

interface Props {
  date: string | Date;
  format?: string;
}

export const FormattedDate: FunctionComponent<Props> = ({
  date,
  format = "YYYY-MM-SS"
}) => {
  const parsedDate = DayJs(date);
  return <span>{parsedDate.format(format)}</span>;
};

export const FormattedTime: FunctionComponent<Props> = ({
  date,
  format = "HH:mm"
}) => {
  const parsedDate = DayJs(date);
  return <span>{parsedDate.format(format)}</span>;
};
