import { Dispatch, AnyAction } from "redux";

const UPDATE_MODAL_IS_VISIBLE = 'UPDATE_MODAL_IS_VISIBLE';

export interface Modal {
  isVisible: boolean;
}

interface UpdateIsVisible {
  type: typeof UPDATE_MODAL_IS_VISIBLE;
  isVisible: boolean;
}

type ModalActions = UpdateIsVisible;

export interface ModalState {
  isVisible: boolean;
}

const initialState: ModalState = {
  isVisible: false
};

export function updateModalIsVisible(dispatch: Dispatch<AnyAction>) {
  return (isVisible: boolean): void => {
    dispatch({
      type: UPDATE_MODAL_IS_VISIBLE,
      isVisible: isVisible
    });
  };
}

export function modalReducer(state = initialState, action: ModalActions) {
  if (!state) {
    return state;
  }
  switch (action.type) {
    case UPDATE_MODAL_IS_VISIBLE:
      return { ...state, isVisible: action.isVisible };
    default:
      return state;
  }
}
