import React, { Component } from "react";
import { SenderConfig } from "./SenderConfig";

interface State {
  hasLoaded: boolean;
}

export class SenderPage extends Component<{}, State> {
  senderConfig: SenderConfig;

  constructor(props: {}) {
    super(props);
    const script = document.createElement("script");
    script.src = "https://www.gstatic.com/cv/js/sender/v1/cast_sender.js";
    script.async = true;
    document.body.appendChild(script);
    this.senderConfig = new SenderConfig();
    this.state = {
      hasLoaded: false,
    };
  }

  componentDidMount() {
    this.senderConfig.setup();
    // Wait for chromecast setup
    setTimeout(() => {
      this.setState({ hasLoaded: true });
    }, 3000);
  }

  render() {
    if (!this.state.hasLoaded) {
      return (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "400px",
              flexDirection: "column",
            }}
          >
            <h1>Dashboard</h1>
            <p>Laddar...</p>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "400px",
            flexDirection: "column",
          }}
        >
          <h1>Dashboard</h1>
          <button
            type="button"
            onClick={() => {
              this.senderConfig.connect();
            }}
          >
            Connect
          </button>
        </div>
      </div>
    );
  }
}
