import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/Reducer";
import { FormattedTime } from "../../components/Date";
import { CHANNELS, Country } from "../../store/Deposit";
import { FormattedNumber } from "../../components/FormattedNumber";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { FlagIcon } from "../../components/flagIcon/FlagIcon";

const CHANNEL_LOOKUP: Record<CHANNELS, string> = {
  [CHANNELS.AUTOGIRO]: "Autogiro",
  [CHANNELS.BANKGIRO]: "Bankgiro",
  [CHANNELS.DIRECT_DEPOSIT]: "Lysainsättning",
  [CHANNELS.SWISH]: "Swish",
  [CHANNELS.ISK_TRANSFER]: "ISK-flytt",
  [CHANNELS.VIRTUAL_ACCOUNT]: "Virtuellt konto",
  [CHANNELS.MOBILEPAY]: "Mobilepay",
  [CHANNELS.DIRECT_DEBIT]: "Direct debit",
};

const MAX_TABLE_ITEMS = 10;

const CurrencySymbol: Record<Country, string> = {
  [Country.SWEDEN]: "SEK",
  [Country.DENMARK]: "DKK",
  [Country.FINLAND]: "EUR",
  [Country.GERMANY]: "EUR",
};

export const Deposits: FunctionComponent = () => {
  const stream = useSelector((state: RootState) => state.deposits.stream);
  if (!stream || stream.length === 0) {
    return <div>Väntar på data</div>;
  }
  const streamCopy = [...stream]
    .slice(Math.max(stream.length - MAX_TABLE_ITEMS, 0))
    .reverse();
  return (
    <div>
      <h1
        style={{
          fontSize: "60px",
        }}
      >
        Insatt sedan föregående bankdag
      </h1>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div>
          <fieldset>
            <legend>Antal insättningar</legend>
            <div className="giant-number">
              {stream[stream.length - 1].todayCount}
            </div>
          </fieldset>
          <fieldset>
            <legend>Totalt summa</legend>
            <div className="giant-number" style={{ fontSize: "80px" }}>
              <FormattedNumber
                number={
                  stream[stream.length - 1].todayInvestmentAmount +
                  stream[stream.length - 1].todaySavingsAmount
                }
              />{" "}
              kr
            </div>
          </fieldset>
          <fieldset>
            <legend>Insättningar Sparkonto</legend>
            <div className="giant-number" style={{ fontSize: "80px" }}>
              <FormattedNumber
                number={stream[stream.length - 1].todaySavingsAmount}
              />{" "}
              kr
            </div>
          </fieldset>
        </div>
        <fieldset style={{ minWidth: "50%" }}>
          <legend>Senaste insättningarna</legend>
          <table style={{ width: "100%" }}>
            <tbody>
              <TransitionGroup component={null}>
                {streamCopy.map((deposit) => (
                  <CSSTransition
                    key={deposit.time + deposit.amount}
                    timeout={1000}
                    classNames="animated-row"
                    mountOnEnter
                    unmountOnExit
                  >
                    <tr>
                      <td style={{ width: "1%" }}>
                        <FlagIcon
                          code={deposit.country?.toLowerCase() ?? "se"}
                        />
                      </td>
                      <td>
                        <FormattedTime date={deposit.time} />
                      </td>
                      <td>{CHANNEL_LOOKUP[deposit.channel]}</td>
                      <td style={{ fontWeight: "bold", textAlign: "right" }}>
                        <FormattedNumber number={deposit.amount} />
                        <span
                          style={{
                            fontSize: 14,
                            opacity: 0.75,
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          {deposit.country
                            ? CurrencySymbol[deposit.country]
                            : "SEK"}
                        </span>
                      </td>
                    </tr>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </tbody>
          </table>
        </fieldset>
      </div>
    </div>
  );
};
